import { ArrayField, Datagrid, DateField, ReferenceField, TextField } from 'react-admin'

export const ScenariosTable = () => {
  return (
    <ArrayField source="scenarios" label="сценарии">
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="scenarioId" reference="scenarios" label="Название сценария">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="scenarioId" reference="scenarios" label="Дата начала" link={false}>
          <DateField source="startDatetime" label="Дата начала" showTime={true} />
        </ReferenceField>
        <ReferenceField
          source="scenarioId"
          reference="scenarios"
          label="Дата окончания"
          link={false}
        >
          <DateField source="endDatetime" label="Дата окончания" showTime={true} />
        </ReferenceField>
      </Datagrid>
    </ArrayField>
  )
}
