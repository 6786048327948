import {
  Create,
  SimpleForm,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
} from 'react-admin'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classes from '../scenes/Scenes.module.sass'

export const CreateScenario = () => {
  const navigate = useNavigate()
  return (
    <Create title="Создать сценарий" redirect="list">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="name"
          label="Название"
          required={true}
          className={classes.DataFieldContainer}
        />
        <DateTimeInput
          source="startDatetime"
          label="Дата начала"
          required={true}
          className={classes.DataFieldContainer}
        />
        <DateTimeInput
          source="endDatetime"
          label="Дата окончания"
          required={true}
          className={classes.DataFieldContainer}
        />
        <ArrayInput source="scenes" label="Эффекты" required={true}>
          <SimpleFormIterator>
            <ReferenceInput reference="scenes" source="" label="Эффект" perPage={1000}>
              <SelectInput
                optionText="name"
                optionValue="id"
                className={classes.DataFieldContainer}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
