import { Datagrid, List, TextField, EditButton } from 'react-admin'
import { ScenariosTable } from './components/scenariosTable/ScenariosTable'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const DevicesList = () => {
  return (
    <List title="Список устройств" empty={false} pagination={<CustomPagination />} exporter={false}>
      <Datagrid expand={<ScenariosTable />}>
        <TextField source="name" label="Название" />
        <TextField source="deviceId" label="Id устройства" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
