import { DevicesList } from './DevicesList'
import { EditDevice } from './EditDevice'
import { CreateDevice } from './CreateDevice'

const pages = {
  list: DevicesList,
  create: CreateDevice,
  edit: EditDevice,
}

export default pages
