import { CreateScene } from './CreateScene'
import { EditScene } from './EditScene'
import { ScenesList } from './ScenesList'

const pages = {
  list: ScenesList,
  create: CreateScene,
  edit: EditScene,
}

export default pages
