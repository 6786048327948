import { useRecordContext } from 'react-admin'
import Link from '@mui/material/Link'
import config from '../../../constants/config'
import DownloadIcon from '@mui/icons-material/Download'

export const SceneCustomFileField = (props: any) => {
  const record = useRecordContext(props.source)
  if (!record.contentPath) return <span>отсутствует</span>

  return (
    <Link
      href={config.apiUrl + '/' + record.contentPath.toString()}
      underline="hover"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <DownloadIcon fontSize="small" />
      <span style={{ marginLeft: 5 }}>скачать</span>
    </Link>
  )
}
