import config from '../constants/config'
import jsonServerProvider from 'ra-data-json-server'
import { CreateParams, fetchUtils, UpdateParams, UpdateResult } from 'react-admin'
import { RaRecord } from 'ra-core'
import inMemoryJWT from '../auth/inMemoryJWT'

const fetchJson = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = inMemoryJWT.getToken()
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options)
  } else {
    inMemoryJWT.setRefreshTokenEndpoint(`${config.apiUrl}/admin-users/refresh`)
    return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
      if (gotFreshToken) {
        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`)
      }
      return fetchUtils.fetchJson(url, options)
    })
  }
}

const dataProvider = jsonServerProvider(`${config.apiUrl}`, fetchJson)

const myDataProvider = () => {
  return {
    ...dataProvider,
    create: <RecordType extends RaRecord = RaRecord>(resource: string, params: CreateParams) => {
      try {
        let formData = new FormData()
        switch (resource) {
          case 'scenes':
            formData.append('name', params.data.name)

            if (params.data.hasOwnProperty('contentPath') && params.data.contentPath) {
              const contentPath = params.data.contentPath.rawFile
              formData.append('contentPath', contentPath, contentPath.name)
            }

            return fetchJson(`${config.apiUrl}/${resource}`, {
              method: 'POST',
              body: formData,
            }).then(({ json }) => ({
              data: { ...params.data, id: json.id },
            }))
          default:
            return dataProvider.create(resource, params)
        }
      } catch (error) {
        console.log('scene create error: ', error)
        return Promise.reject('error')
      }
    },
    update: <RecordType extends RaRecord = RaRecord>(
      resource: string,
      params: UpdateParams,
    ): Promise<UpdateResult<any>> => {
      try {
        let formData = new FormData()
        switch (resource) {
          case 'scenes':
            formData.append('id', params.id.toString())
            formData.append('name', params.data.name)

            if (params.data.hasOwnProperty('contentPath') && params.data.contentPath) {
              if (params.data.contentPath.hasOwnProperty('rawFile')) {
                const contentPath = params.data.contentPath.rawFile
                formData.append('contentPath', contentPath, contentPath.name)
              } else {
                formData.append('contentPath', params.data.contentPath)
              }
            } else {
              formData.append('contentPath', '')
            }

            return fetchJson(`${config.apiUrl}/${resource}/${params.id}`, {
              method: 'PUT',
              body: formData,
            }).then(({ json }) => ({
              data: { ...params.data, id: json.id },
            }))
          default:
            return dataProvider.update(resource, params)
        }
      } catch (error) {
        console.log('update error: ', error)
        return Promise.reject(error)
      }
    },
  }
}

export default myDataProvider
