import { ScenariosList } from './ScenariosList'
import { CreateScenario } from './CreateScenario'
import { EditScenario } from './EditScenario'

const pages = {
  list: ScenariosList,
  create: CreateScenario,
  edit: EditScenario,
}

export default pages
