import inMemoryJWT from './inMemoryJWT'
import config from '../constants/config'
import { jwtDecode } from 'jwt-decode'
import { Identifier } from 'react-admin'

type LoginProps = {
  username: string
  password: string
}

interface UserIdentity {
  id: Identifier
  fullName?: string
  [key: string]: any
}

type AuthAdminResponse = {
  token: string
  tokenExpiry: number
}

const authProvider = {
  login: ({ username, password }: LoginProps) => {
    const request = new Request(`${config.apiUrl}/admin-users/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
      mode: 'cors',
    })
    inMemoryJWT.setRefreshTokenEndpoint(`${config.apiUrl}/admin-users/refresh`)
    return fetch(request)
      .then((response) => {
        if (response.status === 401 || response.status === 403)
          throw new Error('Неверный логин или пароль')
        if (response.status < 200 || response.status >= 300) {
          throw new Error('Ошибка сети')
        }
        return response.json()
      })
      .then((data: AuthAdminResponse) => {
        const { token, tokenExpiry } = data
        const payload: any = jwtDecode(token)
        // const { role, rules, ...rest } = payload
        localStorage.setItem('profile', JSON.stringify(payload))
        // ability.update(unpackRules(payload.rules))
        return inMemoryJWT.setToken(token, tokenExpiry)
      })
  },
  getIdentity: () =>
    new Promise<UserIdentity>((resolve) => {
      let id = '1'
      let name = 'user'

      type UserData = {
        id: string
        avatar: string
        username: string
      }
      let userData: UserData | null = null
      /**
       * Try to get user info from local storage
       */
      const profile = localStorage.getItem('profile')

      if (profile) userData = JSON.parse(profile)
      /**
       * Save data if the item "profileInfo" exist
       */
      if (userData) {
        id = userData.id
        name = userData.username
      }
      return resolve({
        id,
        fullName: name,
      })
    }),
  logout: () => {
    const request = new Request(`${config.apiUrl}/admin-users/logout`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
    })
    inMemoryJWT.eraseToken()
    localStorage.removeItem('profile')
    return fetch(request)
      .then(() => '/login')
      .catch(() => '/login')
  },
  checkError: async ({ status }: any) => {
    if (status === 401) {
      const request = new Request(`${config.apiUrl}/admin-users/logout`, {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include',
      })
      fetch(request).then()
      inMemoryJWT.eraseToken()
      localStorage.removeItem('profile')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    const profile = localStorage.getItem('profile')
    return profile
      ? Promise.resolve()
      : inMemoryJWT.waitForTokenRefresh().then(() => {
          return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject()
        })
  },
  getPermissions: () => {
    return Promise.resolve()
  },
}

export default authProvider
