import { Datagrid, List, TextField, EditButton, useRecordContext } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import { SceneCustomFileField } from './components/SceneCustomFileField'

type Props = {
  source: string
  label: string
}

const CustomDateField = ({ source }: Props) => {
  const record = useRecordContext()
  let data = record && record[source]
  if (data) {
    const raw = data.split('/')[2]
    data = raw.split('.')[0]
  }
  return <span>{data ? new Date(+data).toLocaleString('ru-RU') : '-'}</span>
}

export const ScenesList = () => {
  return (
    <List title="Список сцен" empty={false} pagination={<CustomPagination />} exporter={false}>
      <Datagrid>
        <TextField source="name" label="Название" />
        <CustomDateField source="contentPath" label="Дата загрузки файла" />
        <SceneCustomFileField source="contentPath" label="Файл" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
