import { Edit, SimpleForm, TextInput } from 'react-admin'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classes from '../scenes/Scenes.module.sass'

export const EditUser = () => {
  const navigate = useNavigate()
  return (
    <Edit title="Редактирование пользователя">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="username"
          label="Логин"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
          className={classes.DataFieldContainer}
        />
        <TextInput
          source="email"
          type="email"
          label="Email"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
          className={classes.DataFieldContainer}
        />
      </SimpleForm>
    </Edit>
  )
}
