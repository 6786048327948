import { Menu } from 'react-admin'
import GroupIcon from '@mui/icons-material/Group'
import DevicesIcon from '@mui/icons-material/Devices'
import { ReactComponent as CalendarIcon } from '../assets/CalendarBlank.svg'
import { ReactComponent as FilmIcon } from '../assets/FilmStrip.svg'

export const CustomMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.Item to="/device-list" primaryText="Устройства" leftIcon={<DevicesIcon />} />
    <Menu.Item to="/scenarios" primaryText="Сценарии" leftIcon={<CalendarIcon width={25} />} />
    <Menu.Item to="/scenes" primaryText="Сцены" leftIcon={<FilmIcon width={25} />} />
    <Menu.Item to="/admin-users" primaryText="Пользователи" leftIcon={<GroupIcon />} />
  </Menu>
)
