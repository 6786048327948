import { DeviceState, SceneInterface } from '../../../interfaces'
import { useState } from 'react'

type Props = {
  deviceConfig: DeviceState
}

const useDeviceItemTableHook = ({ deviceConfig }: Props) => {
  const [activeScenes, setActiveScenes] = useState<SceneInterface[]>([])
  const [open, setOpen] = useState(false)

  const getDuration = (start: number, end: number): string => {
    if (!start) return '00:00:00'
    const startDate = start
    const endDate = end ? end : new Date().getTime()
    const diffMs = endDate - startDate

    const diffSeconds = Math.floor(Math.abs(diffMs) / 1000)
    const seconds = diffSeconds % 60 < 10 ? `0${diffSeconds % 60}` : String(diffSeconds % 60)
    const diffMinutes = Math.floor(diffSeconds / 60)
    const minutes = diffMinutes % 60 < 10 ? `0${diffMinutes % 60}` : String(diffMinutes % 60)
    const diffHrs = Math.floor(diffMinutes / 60)
    const hours = diffHrs < 10 ? `0${diffHrs}` : String(diffHrs)
    // const hours = diffHrs % 24 < 10 ? `0${diffHrs % 24}` : String(diffHrs % 24)

    return `${hours}:${minutes}:${seconds}`
  }

  return {
    activeScenes,
    open,
    setOpen,
    getDuration,
  }
}

export default useDeviceItemTableHook
