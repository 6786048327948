import {
  ArrayInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import classes from '../scenes/Scenes.module.sass'

export const EditDevice = () => {
  const navigate = useNavigate()
  return (
    <Edit title="Редактирование устройства">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="name"
          label="Название"
          required={true}
          className={classes.DataFieldContainer}
        />
        <TextInput
          source="deviceId"
          label="Id устройства"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
          className={classes.DataFieldContainer}
        />
        <ArrayInput source="scenarios" label="Доступные сценарии" required={true}>
          <SimpleFormIterator>
            <ReferenceInput
              reference="scenarios"
              source="scenarioId"
              isRequired={true}
              perPage={1000}
            >
              <SelectInput
                optionText="name"
                optionValue="id"
                label="Сценарий"
                className={classes.DataFieldContainer}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
