import React from 'react'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import russianMessages from 'ra-language-russian'
import { Admin, Resource } from 'react-admin'
import authProvider from './auth/authProvider'
import myDataProvider from './dataProvider/myDataProvider'
import { CustomLayout } from './layout/CustomLayout'
import users from './pages/users'
import deviceList from './pages/deviceList'
import scenarios from './pages/scenarios'
import scenes from './pages/scenes'
import { Dashboard } from './pages/dashboard/Dashboard'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

function App() {
  const dataProvider = myDataProvider()
  return (
    <Admin
      title="Панель управления"
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      disableTelemetry={true}
    >
      <Resource name="admin-users" list={users.list} create={users.create} edit={users.edit} />
      <Resource
        name="device-list"
        list={deviceList.list}
        create={deviceList.create}
        edit={deviceList.edit}
      />
      <Resource
        name="scenarios"
        list={scenarios.list}
        create={scenarios.create}
        edit={scenarios.edit}
      />
      <Resource name="scenes" list={scenes.list} create={scenes.create} edit={scenes.edit} />
    </Admin>
  )
}

export default App
