import { Datagrid, DateField, EditButton, List, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const UsersList = () => (
  <List
    title="Список пользователей"
    empty={false}
    pagination={<CustomPagination />}
    exporter={false}
  >
    <Datagrid>
      <TextField source="username" label="Логин" />
      <TextField source="email" label="Email" />
      <DateField source="registerDate" showTime={true} label="Дата регистрации" />
      <EditButton />
    </Datagrid>
  </List>
)
